<template>
  <section class="cont--alumni">
    <div class="wrap--cont">
      <div class="head--title">ALUMNI & AWARDS</div>
      <div class="cont--textbox">
        <div class="box--left">
          <img src="../../assets/academy/j_shin_2022.png" alt="" />
          <img src="../../assets/academy/al_img0.png" alt="" />
          <img src="../../assets/academy/al_img1.png" alt="" />
        </div>
        <div class="box--right">
          <div
            class="wrap--awards"
            v-for="(item, index) in awardDatas"
            :key="index"
          >
            <div class="a__name" v-html="item.name"></div>
            <div v-html="item.list"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import alImg00 from "@/assets/academy/j_shin_2022.png";
import alImg01 from "@/assets/academy/al_img0.png";
import alImg02 from "@/assets/academy/al_img1.png";

export default {
  name: "Alumni",
  data: () => {
    return {
      img00: alImg00,
      img01: alImg01,
      img02: alImg02,
      awardDatas: [
        {
          name: "Justin Shin",
          list: `<ul class="award--ul">
<li>Ranked 2nd on KPGA Tour Series (38th Shinhan Donghae Open, 2022)</li>
<li>Won KPGA Tour Series (4th Woosung Construction Open, 2022)</li>
<li>Former Team Canada member</li>
<li>Full scholarship at New Mexico State University</li>
<li>Web.com Tour, PGA Tour Canada, PGA Tour China,
Japan Tour, Korean Tour member</li>
</ul>`
        },
        {
          name: "Richard Jung",
          list: `<ul class="award--ul">
<li>Former Team Canada member</li>
<li>2 times PGA Tour China Winner</li>
<li>PGA Tour China, Japan Tour member</li>
</ul>`
        },
        {
          name: "Brian Jung",
          list: `<ul class="award--ul">
<li>Former Team BC member</li>
<li>Full Scholarship at Oregon State University</li>
<li>Ranked 11th on Golf Canada Junior (2011)</li>
<li>PGA Tour China, Asian Tour member</li>
</ul>`
        },
        {
          name: "David Lee",
          list: `<ul class="award--ul">
<li>2015 Golf Management program at North Carolina State University</li>
</ul>`
        },
        {
          name: "Nakul Paliwa",
          list: `<ul class="award--ul">
<li>2015 Golf Management program at University of Nebraska</li>
</ul>`
        },
        {
          name: "Amy Cheng",
          list: `<ul class="award--ul">
<li>2015 Golf Management program at Sam Houston University</li>
</ul>`
        },
        {
          name: "Leon Lee",
          list: `<ul class="award--ul">
<li>2015 Golf Management program at Camosun College</li>
</ul>`
        },
        {
          name: "Susan Hwang",
          list: `<ul class="award--ul">
<li>2014 Golf Management program at New Mexico State University</li>
</ul>`
        }
      ]
    };
  }
};
</script>
