<template>
  <section
    class="sec--col-pro"
    :style="{ backgroundImage: 'url(' + bgImg + ')' }"
  >
    <div class="cont--col">
      <div class="head--title">GM College Program</div>
      <div
        class="cont--textbox"
        v-for="(item, index) in textDatas"
        :key="index"
      >
        <div class="wrap--textbox">
          <div class="txt--img"><img :src="item.img" alt="" /></div>
          <div class="txt--content" v-html="item.desc"></div>
        </div>
        <div class="div--line" v-if="index != 2"></div>
      </div>
      <div class="cont--textbox2">
        <div class="sub--title">
          The following universities are accredited by the PGA of America for
          their PGA Golf Management University Program:
        </div>
        <div class="wrap--comp-box">
          <fieldset class="box--left">
            <legend>USA</legend>
            <div class="wrap--list">
              <ul class="el--list">
                <li
                  v-for="(item, index) in usText1"
                  :key="index"
                  v-html="item"
                ></li>
              </ul>
              <ul class="el--list">
                <li
                  v-for="(item, index) in usText2"
                  :key="index"
                  v-html="item"
                ></li>
              </ul>
            </div>
          </fieldset>
          <fieldset class="box--right">
            <legend>CANADA</legend>
            <div class="wrap--list">
              <ul class="el--list">
                <li
                  v-for="(item, index) in caText"
                  :key="index"
                  v-html="item"
                ></li>
              </ul>
            </div>
          </fieldset>
        </div>
        <div class="wrap--cond-app">
          <div class="app--title">Conditions for applying</div>
          <ul class="cont--applying">
            <li
              class="list"
              v-for="(item, index) in lSecData"
              :key="index"
              v-html="item"
            ></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import leftBgImg from "@/assets/academy/sec_col_bg.jpg";
import s3Mark02 from "@/assets/academy/s3marks/ncaa.png";
import s3Mark03 from "@/assets/academy/s3marks/pga.png";
import s3Mark01 from "@/assets/academy/s3marks/school42.png";
export default {
  name: "CollegeProgram",
  components: {},
  data: () => {
    return {
      bgImg: leftBgImg,
      textDatas: [
        {
          img: s3Mark01,
          desc:
            "Since 2007, Golf Mecca Academy has been named as an official golf education institute by BC School District 42 (Maple Ridge, Pitt meadows).\n" +
            "GMA has been guiding more than 50 young golfers into either NCAA/NAIA Full Scholarship program or Golf Management Program across North America."
        },
        {
          img: s3Mark02,
          desc: `<span class="title">NCAA/NAIA Full Scholarship Program</span><br>At Golf Mecca, our professionals are dedicated to guide our elite juniors to realize
their maximum potentials and achieve full scholarships from internationally recognized colleges across North America.`
        },
        {
          img: s3Mark03,
          desc: `<span class="title">PGA Golf Management Program</span><br>The PGA Golf Management University Program, a 4.5- to 5-year college curriculum for aspiring PGA Professionals is offered at PGA accredited universities nationwide. The program provides students the opportunity to acquire the knowledge and skills necessary for success in the golf industry through extensive classroom studies and internship experience.
When you graduate, in addition to your college degree, you become a PGA member with 100 percent job placement.`
        }
      ],
      subTitle:
        "The following universities are accredited by the PGA of America for their PGA Golf Management University Program:",
      usText1: [
        "Arizona State University",
        "Clemson University",
        "Eastern Kentucky",
        "Florida Gulf Coast",
        "Methodist University",
        "New Mexico State",
        "University of Colorado",
        "Central Oklahoma",
        "University of Nebraska"
      ],
      usText2: [
        "Campbell University",
        "Coastal Carolina",
        "Ferris State",
        "Florida State",
        "Mississippi State",
        "North Carolina State",
        "Sam Houston",
        "University of Idaho",
        "Maryland Eastern Shore"
      ],
      caText: [
        "MacEwan University",
        "Humber College",
        "Camosun College",
        "Holland College",
        "Niagara College",
        "Lethbridge College",
        "Georgia College",
        "St. Clair College"
      ],
      lSecData: [
        "Official Golf Handicap of at least 8",
        "Official Reference Letter from Class A PGA Professional",
        "You must earn at least a 2.3 GPA in your core courses",
        "You must graduate high school",
        "Grades from tenth, eleventh and graduation year will be taken into account",
        "Additional SAT or IBT/IELTS scores will be required for colleges in USA"
      ]
    };
  }
};
</script>
