<template>
  <div id="academy">
    <Hero></Hero>
    <LessonProgram id="sec0"></LessonProgram>
    <CollegeProgram id="sec2"></CollegeProgram>
    <InterProgram id="sec3"></InterProgram>
    <Instructors id="sec4"></Instructors>
    <Alumni id="sec5"></Alumni>
    <fix-inquiry></fix-inquiry>
  </div>
</template>

<script>
import Hero from "../components/academy/Hero";
import LessonProgram from "../components/academy/LessonProgram";
import CollegeProgram from "../components/academy/CollegeProgram";
import InterProgram from "../components/academy/InterProgram";
import Instructors from "../components/academy/Instructors";
import Alumni from "../components/academy/Alumni";
import FixInquiry from "../components/academy/FixInquiry";

export default {
  name: "academy",
  components: {
    Hero,
    LessonProgram,
    Alumni,
    CollegeProgram,
    InterProgram,
    Instructors,
    FixInquiry
  },
  methods: {},
  mounted() {}
};
</script>
