<template>
  <section class="cont--inq animated fadeIn">
    <ul class="wrap--inq" v-if="!sendEmailFlag">
      <li class="list">INQUIRY</li>
      <li class="list">
        <div class="error--msg animated fadeIn" v-if="pNameErrTxt != ''">
          {{ pNameErrTxt }}
        </div>
        <input type="text" :placeholder="pNameTxt" v-model="name" id="name" />
      </li>
      <li class="list">
        <div class="error--msg animated fadeIn" v-if="pEmailErrTxt != ''">
          {{ pEmailErrTxt }}
        </div>
        <input
          type="text"
          :placeholder="pEmailTxt"
          v-model="email"
          id="email"
        />
      </li>
      <li class="list">
        <div class="error--msg animated fadeIn" v-if="pPhoneErrTxt != ''">
          {{ pPhoneErrTxt }}
        </div>
        <input
          type="text"
          :placeholder="pPhoneTxt"
          v-model="phone"
          id="phone"
        />
      </li>
      <li class="list">
        <div class="error--msg animated fadeIn" v-if="pCategoryErrTxt != ''">
          {{ pCategoryErrTxt }}
        </div>
        <select name="" v-model="category" id="category">
          <option value="0">Choose Category</option>
          <option value="1">General Lesson</option>
          <option value="2">Elite Junior Program</option>
        </select>
      </li>
      <li class="list">
        <input type="submit" value="SEND" @click="checkForm" />
      </li>
    </ul>
    <div class="wrap--error" v-if="sendEmailFlag">Send your request ...</div>
  </section>
</template>

<script>
import $ from "jquery";
export default {
  name: "fixInquiry",
  data: () => {
    return {
      isInquiry: false,
      name: null,
      email: null,
      phone: null,
      category: 0,
      pNameTxt: "Name *",
      pEmailTxt: "email@email.com *",
      pPhoneTxt: "Phone *",
      pNameErrTxt: "",
      pEmailErrTxt: "",
      pPhoneErrTxt: "",
      pCategoryErrTxt: "",
      sendEmailFlag: false
    };
  },
  methods: {
    setNavAbout: () => {
      let wid = $(this).innerWidth();
      if (wid > 990) {
        $(".cont--inq").css({ display: "none" });
      } else {
        $(".cont--inq").css({ display: "flex" });
      }
      $(window).scroll(function() {
        let wid = $(this).innerWidth();
        let scPos = $(document).scrollTop();
        if (wid > 990) {
          if (scPos > 500 && scPos < 6750) {
            $(".cont--inq").css({ display: "flex" });
          } else {
            $(".cont--inq").css({ display: "none" });
          }
        }
      });
    },
    checkForm: function(e) {
      let nameValue = false;
      let emailValue = false;
      let phoneValue = false;
      let categoryValue = false;

      if (!this.name) {
        this.pNameErrTxt = "At least 3 word more!";
        $("#name").css({ "border-color": "yellow" });
      } else if (this.name.length < 3) {
        this.pNameErrTxt = "At least 3 word more!";
        $("#name").css({ "border-color": "yellow" });
      } else {
        this.pNameErrTxt = "";
        $("#name").css({ "border-color": "white" });
        nameValue = true;
      }
      if (!this.email) {
        this.pEmailErrTxt = "At least 5 word more!";
        $("#email").css({ "border-color": "yellow" });
      } else if (this.email.length < 6) {
        this.pEmailErrTxt = "At least 6 word more!";
        $("#email").css({ "border-color": "yellow" });
      } else if (!this.validEmail(this.email)) {
        this.pEmailErrTxt = "Please type email correctly!";
        $("#email").css({ "border-color": "yellow" });
      } else {
        this.pEmailErrTxt = "";
        $("#email").css({ "border-color": "white" });
        emailValue = true;
      }
      if (!this.phone) {
        this.pPhoneErrTxt = "At least 10 digit more!";
        $("#phone").css({ "border-color": "yellow" });
      } else if (this.phone.length < 10) {
        this.pPhoneErrTxt = "At least 10 digit more!";
        $("#phone").css({ "border-color": "yellow" });
      } else if (!this.validPhone(this.phone)) {
        this.pPhoneErrTxt = "Please type number correctly!";
        $("#phone").css({ "border-color": "yellow" });
      } else {
        this.pPhoneErrTxt = "";
        $("#phone").css({ "border-color": "white" });
        phoneValue = true;
      }

      if (this.category == 0) {
        this.pCategoryErrTxt = "Select category correctly!";
        $("#category").css({ "border-color": "yellow" });
      } else {
        this.pCategoryErrTxt = "";
        $("#category").css({ "border-color": "white" });
        categoryValue = true;
      }

      if (nameValue && emailValue && phoneValue && categoryValue) {
        this.sendEmailFlag = true;
        let categoryArr = ["", "General Lesson", "Elite Junior Program"];
        let categoryTxt = categoryArr[this.category];
        this.sendEmail(this.name, this.email, this.phone, categoryTxt);
      } else {
        return;
      }
      e.preventDefault();
    },
    validEmail: email => {
      // eslint-disable-next-line no-useless-escape
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validPhone: no => {
      // eslint-disable-next-line no-useless-escape
      var phoneno = /[0-9;+\-]/;
      return phoneno.test(no);
    },
    sendEmail: function(sndName, sndEmail, sndPhone, sndCategory) {
      let vm = this;
      const baseURI = "https://api.smtp2go.com/v3/email/send";
      const sendData = {
        api_key: "api-A6F7912C30AA11EAA751F23C91C88F4E",
        sender: sndEmail,
        to: ["info@thegolfmecca.com"],
        subject: `[Inquiry Email from Academy Page] [${sndCategory}]`,
        html_body: `<h4 style="font-size:24px; margin-top:10px;margin-bottom:10px;">[Inquiry Email from Academy Page]<br><br>${sndCategory}<br><br></h4>
            <table>
              <tr style="height:20px; border-bottom:1px solid #999;">
                <td style="width:100px; background-color: #ccc; text-transform: uppercase; padding:5px; font-weight:bold;">Name :</td>
                <td style="width:200px; background-color: #ccc; padding:5px;">${sndName}</td>
              </tr>
              <tr style="height:20px; border-bottom:1px solid #999;">
                <td style="width:100px; background-color: #f4f4f4; text-transform: uppercase; padding:5px; font-weight:bold;">Email :</td>
                <td style="width:200px; background-color: #f4f4f4; padding:5px;">${sndEmail}</td>
              </tr>
              <tr style="height:20px; border-bottom:1px solid #999;">
                <td style="width:100px; background-color: #ccc; text-transform: uppercase; padding:5px; font-weight:bold;">Phone :</td>
                <td style="width:200px; background-color: #ccc; padding:5px;">${sndPhone}</td>
              </tr>
              <tr style="height:20px; border-bottom:1px solid #999;">
                <td style="width:100px; background-color: #f4f4f4; text-transform: uppercase; padding:5px; font-weight:bold;">Category :</td>
                <td style="width:200px; background-color: #f4f4f4; padding:5px;">${sndCategory}</td>
              </tr>
            </table>
            <h6 style="font-size:18px;margin-top:20px;">We will reply to email or call you as soon. Thanks</h6>`
      };
      const headers = {
        "Content-Type": "application/json;charset=utf-8"
      };
      this.$http
        .post(baseURI, sendData, { headers })
        .then(result => {
          console.log(result);
          vm.sendEmailFlag = false;
          vm.name = "";
          vm.email = "";
          vm.phone = "";
          vm.category = 0;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  mounted() {
    this.setNavAbout();
  }
};
</script>
