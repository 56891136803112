<template>
  <section class="cont--int-pro">
    <div class="cont--bg">
      <div class="cont--left">
        <div class="head--title">International Program</div>
        <img src="../../assets/academy/sec-int-uvanu.jpg" alt="" />
        <div class="wrap--desc">
          In partnership with UvanU International and School District 42 (Pitt
          Meadows, Maple Ridge), Golf Mecca Academy is the leading institution
          where international students can study abroad and experience golf at
          the same time in North America. Students will learn different cultures
          and engage in unique after school activities while gain highest level
          of golf instruction at Golf Mecca Center.
        </div>
        <div class="btn--more" @click="goLink(0)">DOWNLOAD KOREAN PDF >></div>
        <div class="btn--more">DOWNLOAD CHINESE PDF >></div>
      </div>
      <div
        class="cont--right"
        :style="{ backgroundImage: 'url(' + bgImg + ')' }"
      ></div>
    </div>
  </section>
</template>

<script>
import BgImg from "@/assets/academy/sec-int-bg.jpg";
export default {
  name: "InterProgram",
  data: () => {
    return {
      bgImg: BgImg
    };
  },
  methods: {
    goLink: function(id) {
      let url;
      if (id == 0) {
        url = "http://thegolfmecca.com/others/GM_internation_Kor.pdf";
      } else if (id == 1) {
        url = "http://thegolfmecca.com/others/GM_internation_Kor.pdf";
      }
      window.open(url, "_blank");
    }
  }
};
</script>

<style scoped></style>
