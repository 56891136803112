<template>
  <section
    class="cont--hero2"
    :style="{ 'background-image': 'url(' + img01 + ')' }"
  >
    <img
      :src="bannerImg"
      alt=""
      class="title__img"
      @click="gotoLink()"
      :style="{ cursor: 'pointer' }"
    />

    <img :src="titleImg" alt="" class="title__img2" />

    <div class="scroll_down_wrapper">
      <div class="icon-scroll"></div>
      <img
        src="https://static.papayaplay.com/static/assets/images/bs_mobile/new_teaser/global_register/SCROLLDOWN.png"
        class="scroll_down_img"
      />
    </div>
  </section>
</template>

<script>
import topImg01 from "@/assets/academy/ac_top_bg.jpg";
import topText from "@/assets/academy/ac_top_text.png";
import banner from "@/assets/about/summer_banner.jpg";

export default {
  name: "Hero",
  data: () => {
    return {
      img01: topImg01,
      titleImg: topText,
      bannerImg: banner
    };
  },
  methods: {
    gotoLink: () => {
      const url =
        "https://shop.thegolfmecca.com/products/summer-break-junior-golf-camp";
      window.open(url, "_blank");
    }
  }
};
</script>
