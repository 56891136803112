<template>
  <section class="sec--inst">
    <div class="cont--inst">
      <div class="head--title">Instructors</div>
      <div class="sub--title">
        Collection of golf tips, lessons and instruction from the top
        instructors to improve your game. Find tips for all skill levels. -
        golfchannel.com
      </div>
    </div>
    <ul class="cont--ins-box">
      <li class="box" v-for="(item, index) in instData" :key="index">
        <img :src="item.fImg" alt="" class="face--img" />
        <div class="b--name">{{ item.name }}</div>
        <div class="b--title">{{ item.title }}</div>
        <ul class="b--achi" v-html="item.list"></ul>
        <div
          v-if="index === 0"
          class="btn--more"
          @click="setBioShowDetail(index)"
          :ref="'bioId' + index"
        >
          BIOGRAPHY
        </div>
      </li>
    </ul>
    <div
      class="cont--detail animated fadeIn"
      v-for="(item, index) in instBioData"
      :key="index"
      v-show="isShowItem == index"
      :id="'bioDetail' + index"
    >
      <div class="wrap--cont">
        <div class="cont--textbox">
          <div class="box--left">
            <img :src="item.image0" alt="" />
            <img :src="item.image1" alt="" v-if="item.image1 != null" />
          </div>
          <div class="box--right">
            <div class="btn--close" @click="isShowItem = null">X</div>
            <div class="a__name" v-html="item.name"></div>
            <div class="a__title" v-html="item.title"></div>
            <div v-html="item.achi"></div>
            <div class="b__title">Biography</div>
            <div class="b__desc" v-html="item.desc"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import faceImg0 from "@/assets/academy/bio/ins_tommy.png";
import faceImg2 from "@/assets/academy/bio/fa-hy.jpg";
import faceImg3 from "@/assets/academy/bio/fa-nl.jpg";
import faceImg4 from "@/assets/academy/bio/fa-pk.jpeg";
import faceImg5 from "@/assets/academy/bio/fa-wl.png";
import bioImg11 from "@/assets/academy/bio/bi-tommy.png";
import $ from "jquery";

export default {
  name: "instructors",
  data: () => {
    return {
      isShowItem: null,
      instData: [
        {
          fImg: faceImg0,
          name: "Tommy Lee",
          title: "Founder of Golf Mecca Center",
          list: `<li>CPGA Class A Professional</li>
<li>Director of Golf Mecca Center, Director of
School District 42 Golf Program</li>
<li>Degree in CPGA Professional Golf
Management University Program</li>
<li>Former Golf Team Coach at Thomas Haney
Secondary School</li>
<li>Former coach for two Canadian National
Team members (Justin Shin, Richard Jung)</li>`
        },
        {
          fImg: faceImg4,
          name: "Peter Knill",
          title: "Golf Instructor",
          list: `<li>PGA of Canada Life Professional </li>
          <li>PGA Class A Golf Professional in 1986</li>
          <li>P.G.A Teaching & Coaching Level III Certification Program</li>
          <li>Hosted weekly golf tips on City tv, and in 2013 on C.T.V.</li>
          <li>More than 30 years of teaching experience</li>
          <li>'Peter Knill possesses the proper conception of the golf swing, and the big thing is he knows how to put it across in the way people understand.'
          <br>Moe Norman, 1992
          <br>(A member of Canadian Golf Hall of Fame)</li>`
        },
        {
          fImg: faceImg2,
          name: "Hyosang Yoon",
          title: "Golf Instructor",
          list: `<li>PGA of Canada Teaching Professional</li>
          <li>CPGA member since 2008 / Class A Professional since 2012</li>
	        <li>Advanced Diploma in Professional Golf Management Program</li>
	        <li>Former HTP at Island View Golf Centre (7years)</li>
	        <li>Former HTP at Ardmore Golf Course (1 year)</li>
	        <li>Former Associate / Teaching Professional at Redwoods Golf Course ( 6.5 years)</li>
	        <li>Cobra Puma Golf Dealer / Club fitter</li>
	        <li>TPI Level 1 Certified, SNAG Certified</li>
	        <li>Titleist Golf ball / Club fitting Certified</li>
	        <li>19 years of teaching experience (taught 1000+ students)</li>
	        <li>Played in multiple VIPT event</li>`
        },
        {
          fImg: faceImg3,
          name: "Noah Lee",
          title: "Golf Instructor",
          list: `<li>CPGA 2020</li>
	        <li>TPI fitness Level 2 certified</li>
	        <li>TPI medical Level 2 certified</li>
	        <li>TPI Junior Level 2 certified</li>
	        <li>Superspeed Level 2 certified</li>
	        <li>Kmotion Level 1 certified</li>`
        },{
          fImg: faceImg5,
          name: "Willis Lee",
          title: "Golf Instructor",
          list: `<li>Played junior golf tour since 7 years old</li>
	        <li>Has won numerous junior tournaments</li>
	        <li>Got a college golf scholarship at Southeastern Oklahoma State University</li>
	        <li>Golf team member at Southwestern Oklahoma State University and Hawaii State University
Willis Lee has played for the Southwestern Oklahoma State University and University of Hawaii at Hilo. He has played a key role on a Vulcan team that finished second at the Pacific West Conference Championships with a score of 74-71-72 at Waikalola golf course and the best round of the year at Hawaii was 69 to finish 5th and Pearl Country club.</li>`
        }
      ],
      instBioData: [
        {
          image0: bioImg11,
          image1: "",
          name: "Tommy Lee",
          title: "Founder of Golf Mecca Center",
          achi: `<ul class="award--ul">
                <li>CPGA Class A Professional</li>
                <li>Director of Golf Mecca Center</li>
                <li>Director of School District 42 Golf Program</li>
                <li>Degree in CPGA Professional Golf Management University Program</li>
                <li>Former Golf Team Coach at Thomas Haney Secondary School</li>
                <li>Former coach for two Canadian National Team members<br>(Justin Shin, Richard Jung)</li>
                </ul>`,
          desc: `Tommy Lee graduated from Humber College with a Diploma in PGA Golf Management program and joined Canadian Professional Golfers Association in 2012. He founded Golf Mecca Center with two missions in mind: coaching elite juniors and inviting international talents into Canada.<br><br>
As a result, he successfully trained Justin Shin and Richard Jung to gain international accomplishments in North America and Asia. He also helped many international students’ admissions into internationally recognized colleges and universities across North America.<br><br>
He is continuously extending his in-depth knowledge in golf coaching and club-fitting for his students.<br><br>`
        }
      ]
    };
  },
  methods: {
    setBioShowDetail: function(id) {
      console.log(id);
      const top = this.$refs;
      let wid = $(window).innerWidth();
      if (wid < 990) {
        let topPos = top["bioId" + id][0].offsetParent.offsetTop;
        let lastPos = topPos + 300 * (id + 1);
        if (id == 0) {
          lastPos = topPos;
        }
        if (id == 1) {
          lastPos = topPos;
        }
        if (id == 2) {
          lastPos = topPos;
        }
        let obj = "#bioDetail" + id;
        $(obj).css({ top: lastPos + "px" });
      } else {
        let obj = "#bioDetail" + id;
        $(obj).css({ top: 0 });
      }
      this.isShowItem = id;
      $(window).resize(function() {
        let wid = $(window).innerWidth();
        if (wid < 990) {
          let topPos = top["bioId" + id][0].offsetParent.offsetTop;
          let lastPos = topPos + 300 * (id + 1);
          if (id == 0) {
            lastPos = topPos;
          }
          if (id == 1) {
            lastPos = topPos;
          }
          if (id == 2) {
            lastPos = topPos;
          }
          let obj = "#bioDetail" + id;
          $(obj).css({ top: lastPos + "px" });
        } else {
          let obj = "#bioDetail" + id;
          $(obj).css({ top: 0 });
        }
      });
    }
  }
};
</script>
