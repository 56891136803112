<template>
  <section class="sec--lp">
    <div class="wrap--top-ts">
      <div class="top--title" v-html="sectionTxt.title"></div>
      <div class="top--desc" v-html="sectionTxt.desc"></div>
    </div>
    <fieldset class="cont--personal">
      <legend>What’s included in the lesson?</legend>
      <div class="wrap--list">
        <ul class="left--list">
          <li
            v-for="(item, index) in legendBoxLeft"
            :key="index"
            v-html="item"
          ></li>
        </ul>
        <ul class="right--list">
          <li
            v-for="(item, index) in legendBoxRight"
            :key="index"
            v-html="item"
          ></li>
        </ul>
      </div>
    </fieldset>
    <ul class="sub--menus">
      <li
        class="menu"
        :class="{ active: isShowItem == 0 }"
        @click="isShowItem = 0"
      >
        MECCA ACADEMY
      </li>
      <li
        class="menu"
        :class="{ active: isShowItem == 1 }"
        @click="isShowItem = 1"
      >
        Prestige PROGRAM
      </li>
      <li
        class="menu"
        :class="{ active: isShowItem == 2 }"
        @click="isShowItem = 2"
      >
        Playing LESSON
      </li>
    </ul>
    <div
      class="cont--detail animated fadeIn"
      v-for="(item, index) in boxDatas"
      :key="index"
      v-show="isShowItem == index"
      :class="'pos' + index"
    >
      <div class="cont--bg">
        <div class="wrap--left">
          <div class="top--title" v-html="item.title"></div>
          <div class="top--desc">{{ item.desc }}</div>
          <table class="tables" v-html="item.priceTable"></table>
          <div class="notice--txt" v-html="item.noticeTxt"></div>
        </div>
        <div
          class="wrap--right"
          :style="{ 'background-image': 'url(' + item.bg + ')' }"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
import BoxBg01 from "@/assets/academy/mecca_academy.png";
import BoxBg02 from "@/assets/academy/prestige_lesson.png";
import BoxBg03 from "@/assets/academy/playing_lesson.png";
export default {
  name: "LessonProgram",
  data: () => {
    return {
      isShowItem: 0,
      sectionTxt: {
        title: "General Lesson Program",
        desc: `The general lessons are 45 minutes or 1hour in duration and are exclusively facilitated and delivered by our trained PGA Professionals.
        Don’t just go to a driving range and hit balls to an empty field. Instead, come to our world class facilities and technology to improve your game.`
      },
      legendBoxLeft: [
        "Access to all amenities at Swan-E-Set Bay Resort",
        "Record, analyze & diagnose your swing by <span class='txt--bold'>V1 Swing Analysis</span>",
        "Analyze your putting stroke by <span class='txt--bold'>Quintic Ball Roll System</span><br>(Quintic Putting Lesson only)"
      ],
      legendBoxRight: [
        "Access to GM private-studio & all practice facilities",
        "Analyze & diagnose your ball flight patterns by<br><span class='txt--bold'>Trackman Launch Monitor</span>",
        "Unlimited range balls"
      ],

      boxDatas: [
        {
          id: 0,
          bg: BoxBg01,
          title: `MECCA ACADEMY`,
          desc: `Lesson of one on one personalized instruction. Immediate feedback and swing prescriptions to implement into your practice.`,
          priceTable: `
<tr class="lineBold"><th colspan="3" class="sub-title">[ General ]</th></tr>
<tr>
                      <th></th>
                      <th>1 Hour</th>
                      <th>45 Mins</th>
                    </tr>
                    <tr>
                      <td>PRIVATE</td>
                      <td>$120</td>
                      <td>$100</td>
                    </tr>
                    <tr>
                      <td>PACKAGE 5</td>
                      <td>$510</td>
                      <td>$425</td>
                    </tr>
                    <tr>
                      <td>PACKAGE 10</td>
                      <td>$960</td>
                      <td>$800</td>
                    </tr>
                    <tr>
                      <td>SEMI-PRIVATE</td>
                      <td>$90 / Person</td>
                      <td>N/A</td>
                    </tr>
                    <tr>
                      <td>PACKAGE 5 SEMI</td>
                      <td>$385 / Person</td>
                      <td>N/A</td>
                    </tr>
                    <tr>
                      <td>PACKAGE 10 SEMI</td>
                      <td>$720 / Person</td>
                      <td>N/A</td>
                    </tr>
                    `,
          noticeTxt:
            "* All prices are in CDN currency and do not include applicable taxes."
        },
        {
          id: 1,
          bg: BoxBg02,
          title: `Prestige Lesson`,
          desc: `Lesson of one on one personalized instruction with head professional & tour pro. Immediate feedback and swing prescriptions to implement into your practice.`,
          priceTable: `
                    <tr class="lineBold">
                      <th></th>
                      <th>1 Hour</th>
                    </tr>
                    <tr>
                      <td class='ltpd_title'><div>Private</div></td>
                      <td>$160</td>
                    </tr>
                    <tr>
                      <td class='ltpd_title'><div>Package of 5</div></td>
                      <td>$720</td>
                    </tr>
                    <tr>
                      <td class='ltpd_title'><div>Package of 10</div></td>
                      <td>$1,360</td>
                    </tr>
                    `,
          noticeTxt:
            "* All prices are in CDN currency and do not include applicable taxes."
        },
        {
          id: 2,
          bg: BoxBg03,
          title: `Playing Lesson`,
          desc: `Enjoy on-course instruction and insights on strategy from one of our PGA coaches to improve your decision-making and course management skills.`,
          priceTable: `
                    <tr class="lineBold">
                      <th></th>
                      <th>9 Holes</th>
                      <th>18 Holes</th>
                    </tr>
                    <tr>
                      <td>1:1</td>
                      <td>$250</td>
                      <td>$400</td>
                    </tr>
                    <tr>
                      <td>1:2</td>
                      <td>$200 / Person</td>
                      <td>$250 / Person</td>
                    </tr>
                    <tr>
                      <td>1:3</td>
                      <td>$150 / Person</td>
                      <td>$200 / Person</td>
                    </tr>
                    `,
          noticeTxt: `* green fees and cart fees are not included.`
        }
      ]
    };
  }
};
</script>
